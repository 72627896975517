import LanguageService from './LanguageService'
let DataService = {};
DataService.listStatus = [
  {
    get label() {
      return LanguageService?.lang?.t_block ?? "t_block"
    },
    value: 'true',
  },
  {
    get label() {
      return LanguageService?.lang?.t_active ?? "t_active"
    },
    value: 'false',
  },
];

DataService.listProvince = [
  {
    get label() {
      return LanguageService?.lang?.t_tỉnh ?? "t_tỉnh"
    },
    value: "Tỉnh"
  },
  {
    get label() {
      return LanguageService?.lang?.t_tp ?? "t_tp"
    },
    value: "TP"
  }
]

DataService.listDistrict = [
  {
    get label() {
      return LanguageService?.lang?.t_quận ?? "t_quận"
    },
    value: "Quận"
  },
  {
    get label() {
      return LanguageService?.lang?.t_huyện ?? "t_huyện"
    },
    value: "Huyện"
  },
  {
    get label() {
      return LanguageService?.lang?.t_thị_xã ?? "t_thị_xã"
    },
    value: "Thị xã"
  },
  {
    get label() {
      return LanguageService?.lang?.t_thành_phố ?? "t_thành_phố"
    },
    value: "Thành phố"
  }
]

DataService.listCommune = [
  {
    get label() {
      return LanguageService?.lang?.t_xã ?? "t_xã"
    },
    value: "Xã"
  },
  {
    get label() {
      return LanguageService?.lang?.t_phường ?? "t_phường"
    },
    value: "Phường"
  },
  {
    get label() {
      return LanguageService?.lang?.t_thị_trấn ?? "t_thị_trấn"
    },
    value: "Thị trấn"
  }
]

DataService.listDate = [
  {
    get label() {
      return LanguageService?.lang?.t_thu_hai ?? "t_thu_hai";
    },
    value: "Thứ Hai"
  },

  {
    get label() {
      return LanguageService?.lang?.t_thu_ba ?? "t_thu_ba";
    },
    value: "Thứ Ba"
  },

  {
    get label() {
      return LanguageService?.lang?.t_thu_tu ?? "t_thu_tu";
    },
    value: "Thứ Tư"
  },

  {
    get label() {
      return LanguageService?.lang?.t_thu_nam ?? "t_thu_nam";
    },
    value: "Thứ Năm"
  },

  {
    get label() {
      return LanguageService?.lang?.t_thu_sau ?? "t_thu_sau";
    },
    value: "Thứ Sáu"
  },

  {
    get label() {
      return LanguageService?.lang?.t_thu_bay ?? "t_thu_bay";
    },
    value: "Thứ Bảy"
  },

  {
    get label() {
      return LanguageService?.lang?.t_chu_nhat ?? "t_chu_nhat";
    },
    value: "Chủ Nhật"
  }
]

export default DataService;
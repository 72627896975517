<script setup>
import Layout from "../../layouts/main.vue";
import { onMounted, reactive, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import MethodService from "../../service/MethodService";
import DataService from "../../service/DataService";
import Swal from "sweetalert2";
import toastr from "toastr";
import DataForm from "./data-dich-vu";
import { FormInstance } from "element-plus";
import mushroom from "cem-primary-api";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
// lấy ngôn ngữ từ pinia store
import { langWeb } from "../../stores/lang";
import LanguageService from "../../service/LanguageService";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const rolesAccount = ref("");
let loading = ref(true);
const router = useRouter();
const route = useRoute();

const ruleFormRef = ref(FormInstance);
const validForm = reactive({ value: DataForm.tableRules.dataSearch.valid });
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const dataTables = reactive({ value: [] }); // data bảng

const listStatus = ref(DataService.listStatus);
const listType = ["probe", "bras"];
const fn_handle = (type, scope, row) => {
  if (type === "update") {
    router
      .push({
        name: "CapNhatDichVu",
        params: { id: row.id },
      })
      .catch(() => {});
  }
  if (type === "delete") {
    Swal.fire({
      title: t("t_warning"),
      text: t("t_service_delete"),
      icon: "warning",
      confirmButtonColor: "#f46a6a",
      confirmButtonText: t("swal_accept"),
      cancelButtonText: t("swal_cancel"),
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await mushroom.service.deleteAsync({
            id: row.id,
          });
          if (response.result) {
            toastr.success(t("toastr_delete_service_success"));
            getService();
          }
        } catch (e) {
          console.error("Có lỗi: %o", e);
        }
      }
    });
  }
};

// Hiển thị form tìm kiếm
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
  let query = Object.assign({}, route.query);
  query.showFormSearch = tableRules.showFormSearch;
  router.push({
    name: "DanhSachDichVu",
    query: query,
  });
};

// Tìm kiếm
const fn_TimKiem = (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (valid) {
      tableRules.dataSearch.value["name"] = tableRules.dataSearch.value["name"]
        ? tableRules.dataSearch.value["name"].trim()
        : "";
      tableRules.dataSearch.value["fullname"] = tableRules.dataSearch.value[
        "fullname"
      ]
        ? tableRules.dataSearch.value["fullname"].trim()
        : "";
      tableRules.filters = MethodService.filterTable(
        JSON.stringify(tableRules.dataSearch)
      );
      tableRules.offset = 0;
      tableRules.page = 1;
      getService();
    } else return false;
  });
};

// Lấy danh sách dịch vụ
const getService = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    limit: tableRules.limit,
    offset: tableRules.offset,
    page: tableRules.page,
    sort: tableRules.sort,
    showFormSearch: tableRules.showFormSearch,
  };
  router
    .replace({
      name: "DanhSachDichVu",
      query: {
        ...dataFilter,
      },
    })
    .catch(() => {});
  try {
    const serviceApiRes = await mushroom.service.listAsync(dataFilter, {
      enabledCache: false,
    });
    if (serviceApiRes.result) {
      dataTables.value = serviceApiRes.result;
      tableRules.total = serviceApiRes.meta.total;
      tableRules.page = tableRules.offset / tableRules.limit + 1;
      loading.value = false;
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};

const fn_hashUrl = () => {
  MethodService.hashUrl(route.query, tableRules);
};

// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  fn_tableChangeOffset(1);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = () => {
  // fn_tableChangeOffset(page);
};
const fn_tableNextClick = () => {
  // fn_tableChangeOffset(page);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  getService();
};
const fn_tableSortChange = (column, tableSort) => {
  tableSort = tableRules;
  MethodService.tableSortChange(column, tableSort);
  getService();
};

// chuyển sang màn thêm mới dịch vụ
const goToAddService = () => {
  router.push({ name: "ThemMoiDichVu" });
};

const goToConfigDisplayService = () => {
  router
    .push({
      name: "CauHinhHienThiDichVu",
    })
    .catch(() => {});
};

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    listStatus.value = [
      {
        label: LanguageService?.lang?.t_block ?? "t_block",
        value: "true",
      },
      {
        label: LanguageService?.lang?.t_active ?? "t_active",
        value: "false",
      },
    ];
  }
);

onMounted(() => {
  const response = JSON.parse(localStorage.getItem("userInfo"));
  rolesAccount.value = response.result.roles.includes("Admin");
  fn_hashUrl();
  getService();
});
</script>

<template>
  <Layout>
    <div class="card" style="min-height: 80vh">
      <div class="card-header">
        <h3 class="text-uppercase mb-0">{{ $t("t-manager-service") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
            <button
              class="btn btn-secondary btn-border mx-2"
              @click="goToAddService"
              v-if="rolesAccount"
            >
              <i class="ri-add-circle-line"></i> {{ $t("t-add") }}
            </button>
            <button
              class="btn btn-info btn-border"
              @click="goToConfigDisplayService"
              v-if="rolesAccount"
            >
              <i class="ri-drag-move-2-fill"></i>
              {{ $t("t-sort") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <!-- Start form search -->
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  ref="ruleFormRef"
                  :model="tableRules.dataSearch.value"
                  :rules="validForm.value"
                  label-position="top"
                  @submit.prevent
                >
                  <b-row>
                    <b-col md="6">
                      <el-form-item :label="$t('t-service-name')" prop="name">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['name']"
                          :placeholder="$t('t-place-service-name')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <el-form-item
                        :label="$t('t-service-fullname')"
                        prop="fullname"
                      >
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['fullname']"
                          :placeholder="$t('t-place-service-fullname')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6" v-if="rolesAccount">
                      <el-form-item :label="$t('t-status')">
                        <el-select
                          v-model="tableRules.dataSearch.value['disabled']"
                          filterable
                          clearable
                          :placeholder="$t('t-place-status')"
                          :no-match-text="$t('t-match-data')"
                          :no-data-text="$t('t-no-data')"
                        >
                          <el-option
                            v-for="item in listStatus"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col md="6" v-if="rolesAccount">
                      <el-form-item :label="$t('t-type-probe-bras')">
                        <el-select
                          v-model="tableRules.dataSearch.value['type']"
                          filterable
                          clearable
                          :placeholder="$t('t-place-type-probe-bras')"
                          :no-match-text="$t('t-match-data')"
                          :no-data-text="$t('t-no-data')"
                        >
                          <el-option
                            v-for="item in listType"
                            :key="item"
                            :label="item"
                            :value="item"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <a
                      class="btn btn-soft-secondary btn-border mx-3"
                      style="width: 80px"
                      @click="fn_showFormTimKiem()"
                    >
                      {{ $t("t-hide") }}
                    </a>
                    <button
                      class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                      style="width: 100px"
                      native-type="submit"
                      @click="fn_TimKiem(ruleFormRef)"
                    >
                      {{ $t("t-search") }}
                    </button>
                  </div>
                </el-form>
              </b-card>
            </b-collapse>
          </div>
          <!-- End form search -->
          <div class="mb-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:pageSize="tableRules.limit"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
          <el-table
            size="small"
            :data="dataTables.value"
            style="width: 100%"
            max-height="550"
            v-loading="loading"
            :empty-text="$t('t-no-data')"
            @sort-change="fn_tableSortChange"
            :default-sort="{
              prop: tableRules.defaultSort[0],
              order: tableRules.defaultSort[1],
            }"
          >
            <el-table-column
              prop=""
              :label="$t('t-stt')"
              width="70"
              align="center"
            >
              <template #default="scope">
                <div class="text-center">
                  {{ tableRules.offset + scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              :label="$t('t-services-name')"
              min-width="200"
              :sortable="tableRules.allowSorting"
              show-overflow-tooltip
            />
            <el-table-column
              prop="fullname"
              :label="$t('t-services-fullname')"
              min-width="200"
              show-overflow-tooltip
              :sortable="tableRules.allowSorting"
            />
            <el-table-column
              prop="type"
              :label="$t('t-type-probe-bras')"
              align="center"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="disabled"
              :label="$t('t-status')"
              align="center"
              :sortable="tableRules.allowSorting"
            >
              <template #default="scope">
                <span
                  v-if="!scope.row.disabled"
                  class="badge badge-soft-success text-uppercase"
                  >{{ $t("t-active-user") }}</span
                >
                <span v-else class="badge badge-soft-danger text-uppercase">{{
                  $t("t-block-user")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('t-action')" align="center" width="150">
              <template #default="scope">
                <div class="btn-group-thao-tac">
                  <el-tooltip
                    :content="$t('t-edit')"
                    placement="top"
                    :enterable="false"
                    effect="light"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-success waves-effect waves-light btn-sm"
                      @click="fn_handle('update', scope.$index, scope.row)"
                      :disabled="!rolesAccount"
                    >
                      <i class="ri-edit-box-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    :content="$t('t-delete')"
                    placement="top"
                    :enterable="false"
                    effect="light"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-danger waves-effect waves-light btn-sm"
                      @click="fn_handle('delete', scope.$index, scope.row)"
                      :disabled="!rolesAccount"
                    >
                      <i class="ri-delete-bin-6-line"></i>
                    </button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="mt-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:pageSize="tableRules.limit"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
.listService-move, /* apply transition to moving elements */
.listService-enter-active,
.listService-leave-active {
  transition: all 0.5s ease;
}

.dragArea {
  max-height: calc(100vh - 380px);
  overflow-y: scroll;
}
</style>
